
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

//Ionicons
@import "node_modules/ionicons/dist/scss/ionicons.scss";

//Font awesome
@import "node_modules/font-awesome/scss/font-awesome.scss";

// Notification page - tabs

.box-tab-links {

    padding: 0;
    transition: all 0.5s;

    a {color: black; }

    h3 {
        width: 33%;
        display: block;
        float: left;
        text-align: center;
        padding: 10px 20px;
        &.selected { background-color: #f1d96a; }
    }

    h3:hover { background-color: lightgray; }
    h3+h3 { border-left: 1px solid lightgrey; }

}


// Notification section in MainHeader/NavBar
.logged-profile-info {
    text-align: left;
    border-top: 1px solid lightgray;
    margin-top: 10px;
    padding-top: 10px;
}

.logged-profile-info > img {
    max-width: 45px;
    float: left;
    margin-right: 20px;
}

.navbar-nav > .user-menu > .dropdown-menu > .user-body .logged-profile-info a {
 color: white !important;
 margin-top: 10px;
}

.navbar-nav > .notifications-menu.locales > .dropdown-menu > li .menu > li > a.active {
    background-color: #f59d00;
    color: white;
}

// Fav button
.js-toggle-fav { color: black; }
.js-toggle-fav.active { color: #dd4b39; }

// Fav button in sidebar
 ul.control-sidebar-menu > li {
    position: relative;
    border-bottom: 2px dashed rgba(255,255,255,0.1);
}

.control-sidebar-menu a.js-toggle-fav {
    padding: 10px;
    width: 35px;
    display: inline-block;
    padding: 0;
    position: absolute;
    bottom: 7px;
    right: 10px;
    color: white;
    cursor: pointer;
   /* display: none;*/
    font-size: 1.5em;
    background: none;
    z-index: 1;
}
.control-sidebar-menu a.js-toggle-fav.active {
    color: #dd4b39;
}

.control-sidebar-menu li > a {
    position: relative;
}

.control-sidebar-menu li:hover a.js-toggle-fav {
    display: block;
}

// Notification list
.notifications-list {
    .notification-item {
        .box-widget {

            transition: all 0.3s;

            &:hover {
                box-shadow: 5px 5px 10px grey;
                cursor: pointer;
            }
        }
    }
    .widget-user h3.widget-user-username {  font-size: 1.1em; }
    .widget-user h5.widget-user-desc {
        font-size: 0.8em;
        position: absolute;
        bottom: 0;
    }

    .widget-user .widget-user-header { position: relative; }
    .widget-user .box-footer { min-height: 250px; }
    .notification-text-content { min-height: 170px; }

}



